import React from 'react';

import Widget from 'components/Widget/index';

const PhotosCard = () => (
  <Widget styleName="gx-pink-purple-gradient gx-text-white gx-card-1367-p">
    <h2 className="gx-font-weight-semi-bold gx-text-white">523</h2>
    <p>+18 New Photos</p>
    <p className="gx-text-uppercase gx-fs-sm gx-mb-0">Photos</p>
  </Widget>
);

export default PhotosCard;
