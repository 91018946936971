import axios from 'axios';
export const authUser = (email, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENPOINT}/auth/login`, {
      email,
      password
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log('error de request', error);
      throw error;
    });
};
export const refreshTokenCall = (refreshToken) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENPOINT}/auth/refresh`, {
      refreshToken
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log('error de request', error);
      throw error;
    });
};

export const signupUserCall = async (values) => {
  const userDto = {
    fullname: values.fullName,
    email: values.email,
    password: values.password,
    company: {
      name: values.restaurant_name,
      document: values.restaurant_document.replace(/[^\d]+/g, '')
    }
  };

  return axios
    .post(`${process.env.REACT_APP_API_ENPOINT}/users`, userDto)
    .then((response) => response.data)
    .catch((error) => {
      console.log('error de request', error);
      throw error;
    });
};
