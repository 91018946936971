import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from '../components/CircularProgress';
import { hideMessage, showAuthLoader, userSignIn } from '../appRedux/actions';

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src="/assets/images/demo_restaurant.png"
                alt="Restaurant Man"
              />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.svg" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id="app.userAuth.emailInputNotValid" />
                    )
                  }
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="app.userAuth.insertPassword" />
                  }
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  className="gx-mb-0 gx-btn-cadastro"
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.signInButton" />
                </Button>
              </Form.Item>
              <Form.Item>
                <Link className="gx-login-form-forgot" to="/forgot-password">
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </Form.Item>
              <Form.Item>
                <div className="gx-text-light" style={{ textAlign: 'center' }}>
                  <IntlMessages id="app.userAuth.or" />
                </div>
                <Link to="/signup" className="gx-signup-link">
                  <IntlMessages id="app.userAuth.createAnAccount" />
                </Link>
              </Form.Item>
            </Form>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
