import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomViews from './customViews';

import Main from './main/index';
import asyncComponent from '../util/asyncComponent';

const App = ({ match }) => {
  console.log(`${match.url}main`);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}main`} component={Main} />
        <Route path={`${match.url}custom-views`} component={CustomViews} />
        <Route
          path={`${match.url}plans`}
          component={asyncComponent(() => import('../pages/pricing/'))}
        />
      </Switch>
    </div>
  );
};

export default App;
