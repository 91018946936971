import React, { useEffect } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import IntlMessages from 'util/IntlMessages';
import { message } from 'antd/lib/index';
import CircularProgress from '../components/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userSignUpApi
} from '../appRedux/actions';
import InputMask from 'react-input-mask';

const FormItem = Form.Item;

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const [form] = Form.useForm();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    if (values.password === values.confirm_password) {
      dispatch(showAuthLoader());
      console.log('values', values);
      dispatch(userSignUpApi(values));
    } else {
      message.error('As senhas não coincidem!');
    }
  };

  const validateFullName = (_, value) => {
    if (!value || value.trim().split(' ').length < 2) {
      return Promise.reject(new Error('Por favor, insira seu nome completo!'));
    }
    return Promise.resolve();
  };

  const validarCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src="/assets/images/demo_restaurant.png"
                alt="Restaurant Man"
              />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signUp" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.svg" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
              form={form}
            >
              <FormItem
                name="fullName"
                rules={[
                  {
                    required: true,
                    validator: validateFullName
                  }
                ]}
              >
                <Input placeholder="Nome completo" />
              </FormItem>

              <FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Por favor, insira um e-mail válido!'
                  }
                ]}
              >
                <Input placeholder="E-mail" />
              </FormItem>
              <FormItem
                name="password"
                rules={[
                  { required: true, message: 'Por favor, insira uma senha!' },
                  {
                    min: 8,
                    message: 'A senha deve ter no mínimo 8 caracteres.'
                  } // Regra para mínimo de caracteres
                ]}
              >
                <Input type="password" placeholder="Senha" />
              </FormItem>
              <FormItem
                name="confirm_password"
                dependencies={['password']} // Adiciona a dependência para validar com a senha
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira a senha novamente!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'As duas senhas que você digitou não coincidem!'
                        )
                      );
                    }
                  })
                ]}
              >
                <Input type="password" placeholder="Repita a senha" />
              </FormItem>

              <FormItem
                name="restaurant_name"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, insira o nome do seu restaurante!'
                  }
                ]}
              >
                <Input type="text" placeholder="Nome fantasia - restaurante" />
              </FormItem>
              <FormItem
                name="restaurant_document"
                rules={[
                  () => ({
                    required: true,
                    validator(_, value) {
                      if (!value || validarCNPJ(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('O CNPJ inserido é inválido!')
                      );
                    }
                  })
                ]}
              >
                <InputMask
                  mask="99.999.999/9999-99"
                  placeholder="CNPJ do restaurante"
                >
                  {(inputProps) => <Input {...inputProps} />}
                </InputMask>
              </FormItem>

              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
              </FormItem>
            </Form>
          </div>
          {loader && (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          )}
          {showMessage && message.error(alertMessage)}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
