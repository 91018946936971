import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from '../../lngProvider';
import { setInitUrl } from '../../appRedux/actions';
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from 'appRedux/actions/Setting';
import { THEME_TYPE_DARK } from '../../constants/ThemeSetting';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';

import { SIGNOUT_USER } from 'constants/ActionTypes';
import { refreshToken } from '../../appRedux/actions/Auth';

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />
      )
    }
  />
);

const setLayoutType = () => {
  document.body.classList.remove('boxed-layout');
  document.body.classList.remove('framed-layout');
  document.body.classList.add('full-layout');
};

const setNavStyle = () => {
  document.body.classList.add('full-scroll');
  document.body.classList.add('horizontal-layout');
};

const styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeColor = useSelector(({ settings }) => settings.themeColor);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

  const dispatch = useDispatch();
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const isTokenExpired = (token) => {
    const toleranceInSeconds = 300;

    try {
      if (typeof token !== 'string' || token.split('.').length !== 3) {
        return true;
      }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      const payload = JSON.parse(jsonPayload);
      const currentTime = Date.now() / 1000;
      if (!payload.exp) return true;
      return payload.exp - toleranceInSeconds < currentTime;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    if (authUser) {
      const intervalId = setInterval(checkJwtValidity, 10000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const checkJwtValidity = () => {
    const authTokenValue = localStorage.getItem('auth_token');
    const refreshTokenValue = localStorage.getItem('refresh_token');
    const isAuthTokenExpired = isTokenExpired(authTokenValue);
    const isRefreshTokenExpired = isTokenExpired(refreshTokenValue);

    if (isAuthTokenExpired && !isRefreshTokenExpired) {
      dispatch(refreshToken(refreshTokenValue));
    } else if (isAuthTokenExpired && isRefreshTokenExpired) {
      dispatch({ type: SIGNOUT_USER });
    }
  };

  useEffect(() => {
    document.documentElement.classList.remove('rtl');
    document.documentElement.setAttribute('data-direction', 'ltr');

    if (themeColor) {
      styleSheetLink.href = `/css/${themeColor}.min.css`;
    }
  }, [themeColor, isDirectionRTL]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      styleSheetLink.href = '/css/dark_theme.min.css';
    } else if (document.body.classList.contains('dark-theme')) {
      document.body.classList.remove('dark-theme');
      styleSheetLink.href = '';
    }
  }, [themeType]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
  }, [location.search, dispatch, initURL, location.pathname]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle();
  }, [layoutType, navStyle]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (
        initURL === '' ||
        initURL === '/' ||
        initURL === '/signin' ||
        initURL === '/signup'
      ) {
        history.push('/main/home');
      } else {
        history.push(initURL);
      }
    }
  });

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? 'rtl' : 'ltr'}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
