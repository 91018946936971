export const mediaList = [
  {
    id: 1,
    postion: 0,
    image: 'https://via.placeholder.com/575X372',
    image2: 'https://via.placeholder.com/80X80',
    title: 'Welcome to roadmap new',
    desc: 'Crypto Expert',
  },
  {
    id: 2,
    postion: 0,
    image: 'https://via.placeholder.com/575X372',
    image2: 'https://via.placeholder.com/80X80',
    title: 'Welcome to roadmap',
    desc: 'Crypto Expert',
  },
  {
    id: 3,
    postion: 0,
    image: 'https://via.placeholder.com/575X372',
    image2: 'https://via.placeholder.com/80X80',
    title: 'Welcome to roadmap new',
    desc: 'Crypto Expert',
  },
];

export const greenStepList = [
  {
    id: 1,
    title: 'Welcome to roadmap new',
    image: 'https://via.placeholder.com/575X692',
    subTitle: 'Theory of origami',
    desc: 'Mother nature want you to become a\n'
    + '              part of it and feel the love. There could\n'
    + '              be more which you can take as an\n'
    + '              initiative and collaborate in making this\n'
    + '              world a better world.',
  },
  {
    id: 2,
    title: 'Generate coffee to use in your graphic',
    image: 'https://via.placeholder.com/575X692',
    subTitle: 'Theory of Coffee',
    desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/575X692',
    title: 'A single right green step',
    subTitle: 'Theory of green',
    desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.',
  },

];
