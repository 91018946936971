import antdPT from 'antd/lib/locale-provider/pt_BR';
import enMessages from '../locales/pt_BR.json';

export const ptLang = {
  messages: {
    ...enMessages,
  },
  antd: antdPT,
  locale: 'pt-BR',
};
