import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  REFRESH_TOKEN,
  SIGNUP_USERAPI
} from 'constants/ActionTypes';
import { auth } from '../../firebase/firebase';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess
} from '../../appRedux/actions/Auth';
import {
  authUser,
  refreshTokenCall,
  signupUserCall
} from './../../services/auth';

const createUserWithEmailPasswordRequest = async (userDto) => {
  try {
    const newUser = await signupUserCall(userDto);
    return newUser;
  } catch (error) {
    throw error;
  }
};

const signInUserWithEmailPasswordRequest = async (email, password) => {
  try {
    const authUserResponse = await authUser(email, password);
    return authUserResponse;
  } catch (error) {
    throw error;
  }
};

function* updateJwtTokens({ payload }) {
  const newTokens = yield call(refreshTokenCall, payload);
  if (newTokens?.access_token && newTokens?.refresh_token) {
    console.log('vai setar porra');
    localStorage.setItem('auth_token', newTokens.access_token);
    localStorage.setItem('refresh_token', newTokens.refresh_token);
    localStorage.setItem('companies', JSON.stringify(signInUser.companies));
    if (signInUser.companies.length === 1) {
      localStorage.setItem('companySelected', signInUser.companies[0]);
    }
    console.log('token atualizado com sucesso', newTokens);
  } else {
    console.log('error devo deslogar o usuario', newTokens);
  }
}

function* createUserWithEmailPassword({ payload }) {
  try {
    const login = yield call(createUserWithEmailPasswordRequest, payload);
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      payload.email,
      payload.password
    );
    localStorage.setItem('auth_token', signInUser.access_token);
    localStorage.setItem('refresh_token', signInUser.refresh_token);
    localStorage.setItem('companies', JSON.stringify(signInUser.companies));
    if (signInUser.companies.length === 1) {
      localStorage.setItem('companySelected', signInUser.companies[0]);
    }
    yield put(
      userSignInSuccess({
        auth_token: signInUser.access_token,
        refresh_token: signInUser.refresh_token,
        companies: signInUser.companies
      })
    );
  } catch (error) {
    let message = 'Error ao cadastrar usuario, por favor contate o suporte!';
    if (error?.response.status) {
      message =
        'Usuário ou empresa já cadastrado! Em caso de dúvidas, contate o suporte.';
    }
    yield put(showAuthMessage(message));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    localStorage.setItem('auth_token', signInUser.access_token);
    localStorage.setItem('refresh_token', signInUser.refresh_token);
    localStorage.setItem('companies', JSON.stringify(signInUser.companies));
    if (signInUser.companies.length === 1) {
      localStorage.setItem('companySelected', signInUser.companies[0]);
    }
    yield put(
      userSignInSuccess({
        auth_token: signInUser.access_token,
        refresh_token: signInUser.refresh_token,
        companies: signInUser.companies
      })
    );
  } catch (error) {
    console.log('error', error);
    yield put(showAuthMessage('Usuário ou senha inválidos'));
  }
}

function* signOut() {
  try {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USERAPI, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* refreshToken() {
  yield takeEvery(REFRESH_TOKEN, updateJwtTokens);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    fork(refreshToken)
  ]);
}
